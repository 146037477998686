$(function() {
    //console.log('video-header.js **********************************************');

	var youtubeId = $('#Inspiration_YouTube-Container').data('youtubeid');
    var winTop, winWid, winHei;

    //Init
    var videoInit = false;
    $('#Inspiration_YouTube-Container').YTPlayer();
    var isDesktop = $("[data-isdesktop]").data("isdesktop");

    $('#Inspiration_YouTube-Container').on('YTPReady', function (e) {
        //console.log('video-header.YTPReady');
        
        var thisPlayer = $(this);

        $('.js-video-play').show().animate({ 'opacity': 1 }, { 'duration': 500 });
        //$('.js-video-image').css({display: 'none'});

        trackResize();
        //Wdot.HomepageVideoHelper.anchorHeadline($('.js-video-anchored-headline'), $('.js-video-container'));
        Wdot.HomepageVideoHelper.anchorHeadline($('.js-anchored-headline'), $('.js-anchor-container'));

        if (isDesktop === "False") {
            //console.log("on mobile.play");
            thisPlayer.YTPPlay();
            
            window.setTimeout(function() { 
                //console.log("on mobile.pause");
                thisPlayer.YTPPause();
            }, 500);
        }

        $('.js-video-play').click(function () {
            //console.log("hit play");
            replayVideo()
        });

        $('#Inspiration_YouTube-Container').on('YTPPlay', function (e) {
            //console.log("play video"); 
            if (videoInit == false) {
                videoInit = true;
            }
        });

    });


	function trackResize() {
        //console.log("trackResize()"); 
		$('body').css('overflow', 'hidden'); //GOTTA HIDE SCROLLBAR BEFORE MEASURING
		winWid = $(window).width();
		winHei = $(window).height();
		$('body').css('overflow', 'auto'); //TURN THEM BACK ON AFTER MEASURING

		trackWindowScroll();
	}
	
	function trackWindowScroll() {
        var headerHei = $('.js-global-nav__icon').outerHeight() + $('.globalHeader').outerHeight();
        winTop = $(window).scrollTop();
		winHei = $(window).height();
        var inspHeadHei = $('.js-video-container').height() + headerHei;
        if ((inspHeadHei - winTop) > winHei) {
             //console.log('MOVE UP CONTROLS');
            $('.js-video-controls').css({ 'position': 'fixed' });
        } else {
            //console.log('SCROLL CONTROLS');
            $('.js-video-controls').css({ 'position': 'absolute' });
        }
        // console.log('$(.js-video-container).height(): ' + $('.js-video-container').height());
        // console.log('$(.js-global-nav__icon).height(): ' + $('.js-global-nav__icon').height());
        // console.log('$(.globalHeader).height(): ' + $('.globalHeader').height());
        // console.log('headerHei: ' + headerHei);
        // console.log('winTop: ' + winTop + ' -- ' + winHei + ' -- ' + inspHeadHei);
    }

    function toggleMute(meth) {
        if (meth == 'mute') {
            $('.js-video-mute').attr('data-mode', 'unmute').removeClass('mute').addClass('unmute');
            $('#Inspiration_YouTube-Container').YTPMute();
        } else {
            $('.js-video-mute').attr('data-mode', 'mute').removeClass('unmute').addClass('mute');
            $('#Inspiration_YouTube-Container').YTPUnmute();
        }
    }


    function replayVideo() {
        $('.js-video-play').animate({ 'opacity': 0 }, {
            queue: false,
            duration: 200,
            complete: function() {
                $(this).css({ 'display': 'none' });
            }
        });

        $('.js-video-skip').animate({ 'opacity': 0 }, {
            queue: false,
            duration: 200,
            complete: function () {
                $(this).css({ 'display': 'none' });
            }
        });

        $('.js-video-controls').css({ 'display': 'block' }).animate({ 'opacity': 1 }, { 'duration': 500 });


        window.setTimeout(function() { 
            toggleMute('unmute');
            $('.js-video-pause').attr('data-mode', 'pause');
            $(this).removeClass('play').addClass('pause');
            if(!$('body').hasClass('homepage')){
                $('.js-video-container').removeClass('text-big').addClass('text-small');
            }
            $('.js-video-image').animate({ 'opacity': 0 }, {
                queue: false,
                duration: 300,
                complete: function () {
                    $(this).css({ 'display': 'none' });
                    $('.js-video').css({ 'position': 'relative' });
                }
            });
        }, 500);

        $('#Inspiration_YouTube-Container').YTPPlay();

    }

    $('.js-video-mute').on('click', function() {
        toggleMute($('.js-video-mute').attr('data-mode'));
    });

    $('.js-video-pause').on('click', function() {
        if ($(this).attr('data-mode') == 'play') {
            $(this).attr('data-mode', 'pause');
            $(this).removeClass('play').addClass('pause');
            $('#Inspiration_YouTube-Container').YTPPlay();
        } else {
            $(this).attr('data-mode', 'play');
            $(this).removeClass('pause').addClass('play');

            $('#Inspiration_YouTube-Container').YTPPause();
        }
    });


    var initTimer = window.setTimeout(function() {
        trackResize();

        if ($('#Inspiration_YouTube-Container').width() != undefined) {
            $('.js-video-container').removeClass('novideo');
        }

        //Wdot.HomepageVideoHelper.anchorHeadline($('.js-video-anchored-headline'), $('.js-video-container'));
        Wdot.HomepageVideoHelper.anchorHeadline($('.js-anchored-headline'), $('.js-anchor-container'));

    }, 600);
	
	$(window).scroll(function(event) {
        trackWindowScroll();
    });

	var resizeTimer; // Set resizeTimer to empty so it resets on page load
	$(window).resize(function() {
		clearTimeout(resizeTimer);
		resizeTimer = setTimeout(function(){
            //Wdot.HomepageVideoHelper.anchorHeadline($('.js-video-anchored-headline'), $('.js-video-container'));
            Wdot.HomepageVideoHelper.anchorHeadline($('.js-anchored-headline'), $('.js-anchor-container'));
	    }, 250);
	});
});



